<template>
    <div class="view-container">
        <div class="header">
            <div class="login-box">
                <div class="title">鉴定中心考试</div>
                <CountDown :timeObj="timeObj"/>
                <div class="user-info">
                    <img :src="userAvatar ? userAvatar : require('../../../assets/images/user_img.png')" alt="">
                    <div class="user-name">{{userName}}</div>
                    <i @click="logoutBtn" class="iconfont logout-btn">&#xe60c;</i>
                </div>
            </div>
        </div>
        <div class="main">
            <el-scrollbar class="main-content">
                <div class="exam-name">{{examName}}</div>
                <div class="exam-type-box">
                    <template v-for="(item, index) in examTypeList">
                        <div class="exam-type" v-if="item.name" @click="toExamDetail(item)"
                             :key="`examTypeList_${index}`">
                            <div class="type-img">
                                <img :src="item.imgSrc" alt="">
                            </div>
                            <span>{{item.name}}</span>
                        </div>
                    </template>
                    <div class="exam-type" style="height: 0;padding: 0;margin: 0"></div>
                    <div class="exam-type" style="height: 0;padding: 0;margin: 0"></div>
                </div>
                <div class="no-data" style="display: none">
                    暂无考试
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    import {userLogout, examStuExamPage} from '@/utils/apis'
    import CountDown from '../../../components/student/CountDown.vue'

    export default {
        name: "Exam",
        data() {
            return {
                userName: '',
                userAvatar: '',
                role: '',
                examTypeList: [],
                timeObj: {
                    begin_time: null,
                    end_time: null,
                },
                examName: '',
            }
        },
        components: {
            CountDown
        },
        mounted() {
            this.role = localStorage.getItem('role');
            if (this.role === '5') {
                this.userName = localStorage.getItem('studentName');
                this.userAvatar = localStorage.getItem('studentAvatar');
            }
            this.getExamStuExamPage();
        },
        methods: {
            // 获取主页面信息
            getExamStuExamPage() {
                examStuExamPage().then((res) => {
                    //设置考试的类型：1：考试；2：模拟
                    localStorage.setItem('exam_type', parseInt(res.data.type));
                    this.examName = res.data.name;
                    // this.examTypeList[0].name = res.data.theory_name;
                    // this.examTypeList[1].name = res.data.goods_info_collect_name;
                    // this.examTypeList[2].name = res.data.shop_decoration_name;
                    // this.examTypeList[3].name = res.data.shop_management_name;
                    // this.examTypeList[4].name = res.data.network_promotion_name;
                    // this.examTypeList[5].name = res.data.store_customer_name;
                    // this.examTypeList[6].name = res.data.data_capture_clean_name;
                    this.timeObj.begin_time = res.data.begin_time;
                    this.timeObj.end_time = res.data.end_time;
                    this.examName = res.data.name;
                    // this.examTypeList[0].name = res.data.theory_name;

                    this.timeObj.begin_time = res.data.begin_time;
                    this.timeObj.end_time = res.data.end_time;
                    // this.examTypeList[0].name = res.data.theory_name;
                    // 四级
                    if (res.data.exam_grade === 1) {
                        if (res.data.goods_info_collect_name) {
                            this.examTypeList.push({
                                name: res.data.goods_info_collect_name,
                                path: '/student/examing/goodsInfo/index',
                                imgSrc: require('./../../../assets/images/student/exam-type02.png')
                            })
                        }
                        if (res.data.shop_decoration_name) {
                            this.examTypeList.push({
                                name: res.data.shop_decoration_name,
                                path: '/student/examing/onlineShopDecoration/index',
                                imgSrc: require('./../../../assets/images/student/exam-type03.png')
                            })
                        }
                        if (res.data.shop_management_name) {
                            this.examTypeList.push({
                                name: res.data.shop_management_name,
                                path: '/student/examing/onlineShopManagement/index',
                                imgSrc: require('./../../../assets/images/student/exam-type04.png')
                            })
                        }
                        if (res.data.network_promotion_name) {
                            this.examTypeList.push({
                                name: res.data.network_promotion_name,
                                path: '/student/examing/webPromotion/index',
                                imgSrc: require('./../../../assets/images/student/exam-type05.png')
                            })
                        }
                        if (res.data.store_customer_name) {
                            this.examTypeList.push({
                                name: res.data.store_customer_name,
                                path: '/student/examing/onlineShopCustomerService/index',
                                imgSrc: require('./../../../assets/images/student/exam-type06.png')
                            })
                        }
                        if (res.data.data_capture_clean_name) {
                            this.examTypeList.push({
                                name: res.data.data_capture_clean_name,
                                path: '/student/examing/ECommerceDataCollectionAndCleaning/index',
                                imgSrc: require('./../../../assets/images/student/exam-type07.png')
                            })
                        }
                    } else if (res.data.exam_grade === 24) { // 三级
                        if (res.data.store_visual_design_name) {
                            this.examTypeList.push({
                                name: res.data.store_visual_design_name,
                                path: '/student/exam/onlineShopVisualDesign/index',
                                imgSrc: require('./../../../assets/images/student/exam-type001.png')
                            })
                        }
                        if (res.data.network_marketing_name) {
                            this.examTypeList.push({
                                name: res.data.network_marketing_name,
                                path: '/student/exam/internetMarketing/index',
                                imgSrc: require('./../../../assets/images/student/exam-type002.png')
                            })
                        }
                        if (res.data.online_transaction_management_name) {
                            this.examTypeList.push({
                                name: res.data.online_transaction_management_name,
                                path: '/student/exam/onlineTradeManage/index',
                                imgSrc: require('./../../../assets/images/student/exam-type003.png')
                            })
                        }
                        if (res.data.store_customer_service_level_three_name) {
                            this.examTypeList.push({
                                name: res.data.store_customer_service_level_three_name,
                                path: '/student/exam/internetCustomerService/index',
                                imgSrc: require('./../../../assets/images/student/exam-type06.png')
                            })
                        }
                        if (res.data.data_analysis_application_name) {
                            this.examTypeList.push({
                                name: res.data.data_analysis_application_name,
                                path: '/student/exam/ECommerceDataAnalysisAndApplication/index',
                                imgSrc: require('./../../../assets/images/student/exam-type005.png')
                            })
                        }
                        if (res.data.product_service_management_name) {
                            this.examTypeList.push({
                                name: res.data.product_service_management_name,
                                path: '/student/exam/productSerive/index',
                                imgSrc: require('./../../../assets/images/student/exam-type005.png')
                            })
                        }
                    }else if(res.data.exam_grade === 74){
                      //三级跨境
                      if (res.data.shop_management_name) {
                        this.examTypeList.push({
                          name: res.data.shop_management_name,
                          path: '/student/examing/onlineShopManagement/index',
                          imgSrc: require('./../../../assets/images/student/exam-type04.png')
                        })
                      }
                      if (res.data.product_service_management_name) {
                        this.examTypeList.push({
                          name: res.data.product_service_management_name,
                          path: '/student/exam/productSerive/index',
                          imgSrc: require('./../../../assets/images/student/exam-type005.png')
                        })
                      }
                      if (res.data.data_analysis_application_name) {
                        this.examTypeList.push({
                          name: res.data.data_analysis_application_name,
                          path: '/student/exam/ECommerceDataAnalysisAndApplication/index',
                          imgSrc: require('./../../../assets/images/student/exam-type005.png')
                        })
                      }

                      if (res.data.store_visual_design_name) {
                        this.examTypeList.push({
                          name: res.data.store_visual_design_name,
                          path: '/student/exam/onlineShopVisualDesign/index',
                          imgSrc: require('./../../../assets/images/student/exam-type001.png')
                        })
                      }

                      if (res.data.store_customer_name) {
                        this.examTypeList.push({
                          name: res.data.store_customer_name,
                          path: '/student/examing/onlineShopCustomerService/index',
                          imgSrc: require('./../../../assets/images/student/exam-type06.png')
                        })
                      }

                    }else if(res.data.exam_grade === 51){
                      //四级跨境
                      if (res.data.store_visual_design_name) {
                        this.examTypeList.push({
                          name: res.data.store_visual_design_name,
                          path: '/student/exam/onlineShopVisualDesign/index',
                          imgSrc: require('./../../../assets/images/student/exam-type001.png')
                        })
                      }

                      if (res.data.network_promotion_name) {
                        this.examTypeList.push({
                          name: res.data.network_promotion_name,
                          path: '/student/examing/webPromotion/index',
                          imgSrc: require('./../../../assets/images/student/exam-type05.png')
                        })
                      }

                      if (res.data.shop_management_name) {
                        this.examTypeList.push({
                          name: res.data.shop_management_name,
                          path: '/student/examing/onlineShopManagement/index',
                          imgSrc: require('./../../../assets/images/student/exam-type04.png')
                        })
                      }

                      if (res.data.store_customer_name) {
                        this.examTypeList.push({
                          name: res.data.store_customer_name,
                          path: '/student/examing/onlineShopCustomerService/index',
                          imgSrc: require('./../../../assets/images/student/exam-type06.png')
                        })
                      }

                      if (res.data.data_capture_clean_name) {
                        this.examTypeList.push({
                          name: res.data.data_capture_clean_name,
                          path: '/student/examing/ECommerceDataCollectionAndCleaning/index',
                          imgSrc: require('./../../../assets/images/student/exam-type07.png')
                        })
                      }
                      if (res.data.goods_info_collect_name) {
                        this.examTypeList.push({
                          name: res.data.goods_info_collect_name,
                          path: '/student/examing/goodsInfo/hotGoods',
                          imgSrc: require('./../../../assets/images/student/exam-type02.png')
                        })
                      }
                    }
                    localStorage.setItem('end_time', this.timeObj.end_time);
                }).catch((err) => {
                })
            },
            // 跳转考试详情
            toExamDetail(item) {
                this.$router.push(item.path)
            },
            // 退出
            logoutBtn() {
                userLogout().then((res) => {
                    localStorage.clear();
                    this.$router.push('/');
                    this.$message.success(res.msg);
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .view-container {
        width: 100vw;
        height: 100vh;
        min-width: 1200px;
        background: #F7F8FC;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .header {
            width: 100%;
            height: 60px;
            background: #fff;
            box-shadow: 0 2px 5px 0 rgba(15, 26, 54, 0.05);
            display: flex;
            z-index: 99;

            .login-box {
                width: 100%;
                font-size: 16px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;

                .title {
                    font-size: 18px;
                    font-weight: 400;
                    color: #333;
                }

                img {
                    height: 50px;
                    width: 94px;
                }

                .user-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    .user-name {
                        width: 100px;
                        max-height: 50px;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .logout-btn {
                        cursor: pointer;
                        margin-left: 20px;
                        color: #2DC079;
                    }
                }
            }
        }

        .main {
            width: 100%;
            height: 1%;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url("./../../../assets/images/student/student-exam-bj.png") no-repeat center center;
            background-size: cover;

            .main-content {
                max-height: 724px;
                width: 1300px;
                background: url("./../../../assets/images/student/exam-type-bj.png") no-repeat center center;
                background-size: cover;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                border-radius: 8px;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;

                    .el-scrollbar__view {
                        padding: 114px 60px 170px;
                    }
                }

                .exam-name {
                    line-height: 1;
                    font-size: 30px;
                    font-weight: 500;
                    text-align: center;
                }

                .exam-type-box {
                    margin-top: 38px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .exam-type {
                        cursor: pointer;
                        margin-top: 40px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background: #fff;
                        box-shadow: 0px 0px 13px 0px rgba(56, 56, 56, 0.08);
                        border-radius: 8px;
                        box-sizing: border-box;
                        padding: 30px 20px 20px;
                        width: 280px;
                        transition: 0.3s;

                        span {
                            font-size: 18px;
                            font-weight: 500;
                            margin-top: 20px;
                        }

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                .no-data {
                    margin-top: 38px;
                    font-size: 24px;
                    text-align: center;
                    color: #666;
                }
            }
        }
    }
</style>